<template>
  <div class="lofinmain">
    <div class="login" ref="login">
      <div class="islogin animated fadeIn" v-show="islogin">
        <div class="login_title">
          <span>账号登录</span>
        </div>
        <div class="login_fields">
          <!-- <div class="login_fields__user">
            <div class="icon">
              <img :src="Uic" />
            </div>
            <input placeholder="用户名" type="text" v-model="user" />
            <div class="validation">
              <img :src="Tk" />
            </div>
          </div>
          <div class="login_fields__password">
            <div class="icon">
              <img :src="Lc" />
            </div>
            <input placeholder="密码" type="password" v-model="pwd" />
            <div class="validation">
              <img :src="Tk" />
            </div>
          </div> -->

          <a-form
            :model="formState"
            name="basic"
            autocomplete="off"
            @finish="onFinish"
            @finishFailed="onFinishFailed"
          >
            <a-form-item
              :rules="[
                { required: true, message: 'Please input your username!' },
              ]"
            >
              <a-input v-model:value="formState.username">
                <template #prefix
                  ><UserOutlined style="color: rgba(0, 0, 0, 0.25)" /></template
              ></a-input>
            </a-form-item>

            <a-form-item
              :rules="[
                { required: true, message: 'Please input your password!' },
              ]"
            >
              <a-input-password v-model:value="formState.password">
                <template #prefix
                  ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
                /></template>
              </a-input-password>
            </a-form-item>

            <a-form-item name="remember">
              <a-checkbox
                style="color: white"
                v-model:checked="formState.remember"
                >记住密码</a-checkbox
              >
            </a-form-item>

            <!-- 
            <a-form-item>
              <a-button danger shape="round" html-type="submit" @click="submit"
                >登录</a-button
              >
          
            </a-form-item> -->
          </a-form>
          <div class="login_fields__submit">
            <input type="submit" value="登录" @click="submit" />
          </div>
        </div>
      </div>

      <div class="success animated fadeIn" ref="success">
        <div class="" v-if="successtype">
          <h2>认证成功</h2>
          <p>欢迎回来</p>
          <div class="sspace">
            <p class="animated flash">准备接入主程序...</p>
          </div>
        </div>
        <div class="" v-else>
          <h2>认证失败</h2>
          <p class="point" @click="back">返回</p>
        </div>
      </div>
      <div class="disclaimer">
        <p>默认账号:admin</p>
        <p>默认密码:111</p>
      </div>
    </div>
    <div class="authent animated fadeInLeft" ref="authent">
      <img :src="Puff" />
      <p>正在进行身份验证...</p>
    </div>
  </div>
</template>
<script>
import { ref, reactive } from "vue";
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import Lc from "../assets/login/lock_icon_copy.png";
import Tk from "../assets/login/tick.png";
import Uic from "../assets/login/user_icon_copy.png";
import Puff from "../assets/login/puff.svg";
import { useRouter } from "vue-router";
// import { vuplogin } from "../api/login/login.js";

export default {
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const router = useRouter();
    const islogin = ref(true);
    const login = ref(null);
    const authent = ref(null);
    const success = ref(null);

    const successtype = ref(true);
    const formState = reactive({
      username: "admin",
      password: "111",
      remember: true,
    });

    function submit() {
      if (formState.username === "" || formState.password === "") {
        return;
      }
      login.value.className = "login test";
      setTimeout(function () {
        login.value.className = "login test testtwo";
      }, 300);
      setTimeout(function () {
        authent.value.style.display = "block";
        authent.value.style.opacity = "1";
      }, 500);
      checkpwd();
    }
    async function checkpwd() {
      // try {
      //   let { code, data } = await vuplogin({
      //     userName: "admin",
      //     passWord: "admin",
      //   });
      //   if (code === 200) {
      //     console.log(data);
      //     return;
      //   }
      // } catch (error) {
      //   console.log(error);
      //   return;
      // }

      if (formState.username === "admin" && formState.password === "111") {
        successtype.value = true;
      } else {
        successtype.value = false;
      }
      setTimeout(function () {
        authent.value.style.display = "none";
        authent.value.style.opacity = "0";
        login.value.className = "login test";
      }, 2500);
      setTimeout(function () {
        login.value.className = "login";
        islogin.value = false;
        success.value.style.display = "block";
      }, 3200);
      if (successtype.value) {
        setTimeout(function () {
          localStorage.setItem("token", "testprivatekey");
          router.push("/sk");
        }, 5000);
      }
    }
    function back() {
      islogin.value = true;
      success.value.style.display = "none";
      formState.password = "";
      formState.password = "";
    }
    function onFinish(values) {
      console.log("Success:", values);
    }

    function onFinishFailed(errorInfo) {
      console.log("Failed:", errorInfo);
    }
    return {
      Lc,
      Tk,
      Uic,
      Puff,
      login,
      authent,
      success,
      islogin,

      successtype,
      formState,
      submit,
      back,
      onFinish,
      onFinishFailed,
    };
  },
};
</script>
<style lang="scss" scoped>
.lofinmain {
  .success {
    .sspace {
      margin-top: 60px;
      p {
        font-size: 20px;
        text-align: center;
      }
    }
  }
}
</style>
